.app-messages {
	.subject-input {
		border-width: 0 !important;
	}

	.mce-container {
		border-width: 1px 0 0 0 !important;
	}

	.files-container {
		width: 100%;
		background-color: white;
		color: grey;
	}

	.files-list-container {
		min-height: 7rem;
	}

	.button-area {
		background-color: white;
	}
}
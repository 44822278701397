.collapsing-card {
	.card-header {
		background-image: linear-gradient(120deg, var(--cui-card-cap-bg, rgba(0, 0, 21, 0.03)) 83%, var(--cui-sidebar-bg, #3c4b64) 83%);
		border-bottom: 1px solid lightgray;

		.header-icon {
			color: lightgray;
			font-weight: 800;
		}

		.header-icon.open {
			transform: rotate(0deg);
			transition: transform 0.2s linear;
		}

		.header-icon.close {
			transform: rotate(180deg);
			transition: transform 0.2s linear;
		}
	}
}
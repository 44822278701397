.tooltip-modal {
	.modal-backdrop.show {
		opacity: 0 !important;
	}
	.modal-content {
		background-color: transparent !important;
	}
	.modal-body {
		background-color: rgba(50,50,50,0.9) !important;
	}
}
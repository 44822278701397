.file-upload {
	.filepond--image-preview-overlay-idle svg * {
		display: none;
	}
	.filepond--image-preview-overlay-success svg * {
		display: none;
	}
	.filepond--image-preview-overlay-failure svg * {
		display: none;
	}
}

.ReactModalPortal {
	z-index: 1021;
}

.app-dashboard {
	.card.dashboard-card {

		.select-btn {
			color: var(--cui-secondary);
		}
		.select-btn.active, .select-btn:hover {
			color: inherit;
			background-color: var(--cui-info);
		}
		.select-btn:hover {
			background-color: var(--cui-light);
		}
		.card-body {
			overflow-y: auto;
		}
	}
}

.invitation-list-item:hover {
	border-width: 2px;
}

.action-button {
	position: relative;
}

.action-text {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, -10%);
	opacity: 0.5,
}

.field-selection {
	fieldset {
		border-top: 1px solid blue;
	}

	legend {
		float: initial !important;
		font-size: inherit;
		width: initial;
		padding: 0 3px;
		margin: 0 20px;
		color: blue;
	}
}
